*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    height: 100vh;
    background-color: rgb(24, 24, 24);
}

.webgl
{
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    mix-blend-mode: exclusion;
}

.container {
    height: 100vh;
    display: grid;
    place-items: center;
}

h1 {
    font-size: 8rem;
    text-transform: uppercase;
    color: white;
}
